<script setup lang="ts">
import {
	ref,
	watch,
	computed,
	onMounted,
	StyleValue,
} from 'vue';
import { useRouter } from 'vue-router';
import { useSiteGlobal } from '@zyro-inc/site-modules/use/useSiteGlobal';

import StickyTrigger from '@zyro-inc/site-modules/components/StickyTrigger.vue';
import { scrollToSection } from '@zyro-inc/site-modules/utils/scrollToSection';
import { getGridItemSize } from '@zyro-inc/site-modules/utils/getGridItemSize';
import { objectToCssVariables } from '@zyro-inc/site-modules/utils/objectToCssVariables';
import { useEcwidStore } from '@zyro-inc/site-modules/use/useEcwidStore';

import CookieBanner from '@zyro-inc/site-modules/components/CookieBanner.vue';
import BlockUser from '@zyro-inc/site-modules/components/block/BlockUser.vue';
import PasswordPage from '@zyro-inc/site-modules/components/password-page/PasswordPage.vue';
import EcommerceShoppingCartProviderUser from '@zyro-inc/site-modules/components/ecommerce/EcommerceShoppingCartProviderUser.vue';
import EcommerceModalRoot from '@zyro-inc/site-modules/components/ecommerce/modals/EcommerceModalRoot.vue';
import BlockHeader from '@zyro-inc/site-modules/components/blocks/header/BlockHeader.vue';
import { getHeaderProps } from '@zyro-inc/site-modules/components/blocks/header/getHeaderProps';
import { getIsLocaleWithEcommerce } from '@zyro-inc/site-modules/utils/getters/getIsLocaleWithEcommerce';
import { usePasswordProtection } from '@zyro-inc/site-modules/utils/usePasswordProtection';
import { getPathParams } from '@zyro-inc/site-modules/utils/page/getPathParams';
import { useLightbox } from '@zyro-inc/site-modules/components/lightbox/useLightbox';
import Lightbox from '@zyro-inc/site-modules/components/lightbox/Lightbox.vue';
import { SYSTEM_LOCALE } from '@zyro-inc/site-modules/constants';
import { getIsInIframe } from '@zyro-inc/site-modules/utils/getIsInIframe';
import { useSiteEngineAnimations } from '@zyro-inc/site-modules/use/useSiteEngineAnimations';
import { useEcommerceGlobal } from '@zyro-inc/site-modules/use/useEcommerceGlobal';

const NUMBER_OF_LCP_IMAGES_TO_COMPARE = 2;

const props = defineProps({
	pageData: {
		type: Object,
		required: true,
	},
	isInPreviewMode: {
		type: Boolean,
		default: false,
	},
});

const {
	pages,
	blocks,
	elements,
	nav,
	meta,
	metaTitle,
	ecommerceShoppingCart,
	cookieBannerDisclaimer,
	cookieBannerAcceptText,
	cookieBannerDeclineText,
	currentLocale,
	languageKeys,
	ecwidPages,
	languageSwitcherLanguages,
	siteId,
	homePageId,
	styles,
	isNavHidden,
	currentPageId,
	currentPageData: currentPage,
	getPagePathFromId,
	setPageData,
} = useSiteGlobal();

const {
	isStoreTypeZyro,
	shoppingCartItems,
	setShoppingCartOpen,
} = useEcommerceGlobal();

const {
	openEcwidHomepage,
	openCart,
	ecwidCartItemCount,
} = useEcwidStore();
const { isLightboxOpen } = useLightbox();
const router = useRouter();
const { shouldMountAnimationsInPreview } = useSiteEngineAnimations();

const isNavOpen = ref(false);

const currentPageType = computed(() => currentPage.value.type);
const pageCSSVars = computed(() => objectToCssVariables(styles.value) as StyleValue);
const isHeaderVisible = computed(() => !isNavHidden.value);
const isCurrentPageHomepage = computed(() => currentPageId.value === homePageId.value);
const homepageName = computed(() => pages.value?.[homePageId.value]?.name ?? '');

const shouldRenderPage = computed(() => {
	if (!currentPage.value?.meta?.password || props.isInPreviewMode) {
		return true;
	}

	const { isPageOpen } = usePasswordProtection({
		pagePassword: currentPage.value?.meta?.password ?? '',
		locale: currentLocale.value,
		pageId: currentPageId.value,
	});

	return isPageOpen.value;
});
const pageBlocksSlotFooter = computed(() => {
	if (!currentPage.value || currentPage.value.footerSlotIsHidden) {
		return [];
	}

	const footerBlock = Object.keys(blocks.value).find((blockId) => blocks.value[blockId].slot === 'footer');

	return footerBlock ? [footerBlock] : [];
});
const currentPageBlocks = computed(() => (currentPage.value ? [
	...currentPage.value.blocks,
	...pageBlocksSlotFooter.value,
] : []));

// @ts-ignore until we convert getHeaderProps to ts
const headerProps = computed(() => getHeaderProps({
	siteId: siteId.value,
	meta: meta.value,
	blocks: blocks.value,
	nav: nav.value,
	pages: pages.value,
	elements: elements.value,
	languageMetaTitle: metaTitle.value,
	currentLocale: currentLocale.value,
	currentPageId: currentPageId.value,
	languageSwitcherLanguages: languageSwitcherLanguages.value,
	isLogoOptimized: true,
	shoppingCartItems: shoppingCartItems.value,
	ecwidCartItemCount: ecwidCartItemCount.value,
	getPagePathFromId: ({ pageId }: { pageId: string }) => getPagePathFromId({
		pageId,
	}),
	isOpen: isNavOpen.value,
	ecwidPages: ecwidPages.value,
}));

const headerHeight = computed(() => headerProps.value.height);
const headerHeightMobile = computed(() => headerProps.value.heightMobile);
const currentPageBlockData = computed(() => currentPageBlocks.value.map((id) => blocks.value[id]));
const isLocaleWithEcommerceItems = computed(() => getIsLocaleWithEcommerce({
	blocks: blocks.value,
	elements: elements.value,
}));
const defaultLocale = computed(() => meta.value.defaultLocale ?? SYSTEM_LOCALE);
const ecommerceTranslations = computed(() => {
	if (!isStoreTypeZyro.value) {
		return {};
	}

	return ecommerceShoppingCart.value?.translations ?? {};
});
const language = computed(() => {
	if (!isStoreTypeZyro.value) {
		return null;
	}

	return ecommerceShoppingCart.value?.lang ?? 'en';
});
const currentPageEcommerceBlocks = computed(() => {
	if (!isLocaleWithEcommerceItems.value) {
		return [];
	}

	return currentPageBlockData.value.filter((block) => [
		'BlockEcommerceProduct',
		'BlockEcommerceProductList',
	].includes(block.type));
});
const currentPageEcommerceComponents = computed(() => {
	if (!isLocaleWithEcommerceItems.value) {
		return [];
	}

	const allEcommerceComponents = Object.keys(elements.value)?.filter((id) => elements.value[id].type === 'GridEcommerceButton');

	return allEcommerceComponents.filter((id) => currentPageBlockData.value.some((data) => data.components?.includes(id)))
		.map((id) => elements.value[id]);
});
const computedHeaderHeightStyles = computed(() => {
	const { isTransparent } = blocks.value.header.background ?? {};

	return {
		'--header-height': isTransparent && isHeaderVisible.value ? `${headerHeight.value}px` : null,
		'--header-height-mobile': isTransparent && isHeaderVisible.value ? `${headerHeightMobile.value}px` : null,
	};
});
const lcp = computed(() => {
	const [firstBlockId] = currentPage.value?.blocks ?? [];

	if (blocks.value?.[firstBlockId]?.background?.current === 'image') {
		return {
			type: 'block-background',
			id: firstBlockId,
		};
	}

	if (blocks.value?.[firstBlockId]?.type === 'BlockBlogList') {
		return {
			type: 'block-blog-list',
			id: firstBlockId,
		};
	}

	if (blocks.value?.[firstBlockId]?.type === 'BlockEcommerceProduct') {
		return {
			type: 'block-ecommerce-product',
			id: firstBlockId,
		};
	}

	if (blocks.value?.[firstBlockId]?.type === 'BlockEcommerceProductList') {
		return {
			type: 'block-ecommerce-product-list',
			id: firstBlockId,
		};
	}

	// this should return [{ blockId, elementId }, { blockId, elementId }, ...]
	// because we need both blockId and elementId to get image size
	const allElementIds = currentPageBlocks.value
		.filter((blockId) => blocks.value[blockId]?.components?.length > 0)
		.flatMap((blockId) => blocks.value[blockId].components.map((elementId: string) => ({
			blockId,
			elementId,
		})));

	const firstImages = allElementIds
		.filter(({ elementId }) => elements.value[elementId]?.type === 'GridImage')
		.slice(0, NUMBER_OF_LCP_IMAGES_TO_COMPARE)
		.map(({
			blockId,
			elementId,
		}) => {
			const elementData = elements.value[elementId];

			// Check wether image has 'mobile' or 'desktop' width/height
			// If yes, use them to calculate LCP, otherwise it's ecommerce element and calculate size via `getGridItemSize`
			const sizeInLayout = elementData.mobile ?? elementData.desktop;

			const {
				width,
				height,
			} = sizeInLayout ?? getGridItemSize(
				blocks.value[blockId],
				elementData.settings.styles.position,
			);

			return {
				elementId,
				imageRatio: height / width,
			};
		});

	if (firstImages.length === 0) {
		return {};
	}

	const largestImage = firstImages.reduce(
		(previous, current) => (current.imageRatio > previous.imageRatio ? current : previous),
	);

	return {
		type: 'grid-image',
		id: largestImage?.elementId,
	};
});

const scrollToHash = (hash: string) => {
	if (!hash) {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});

		return;
	}

	scrollToSection(hash);
};

const handleCartClick = () => {
	if (isLocaleWithEcommerceItems.value) {
		setShoppingCartOpen(true);
	}
};

const redirectToThirdPartyLink = (anchorElement: HTMLAnchorElement) => {
	const {
		target,
		href,
	} = anchorElement;
	const shouldOpenInNewTab = target === '_blank';
	const linkOpenMode = shouldOpenInNewTab || getIsInIframe() || props.isInPreviewMode ? '_blank' : '_self';

	window.open(href, linkOpenMode);
};

const handleGlobalClick = async (event: Event) => {
	if (!event.target) {
		return;
	}

	const closestAnchor = (event.target as HTMLElement).closest('a');

	if (!closestAnchor) {
		return;
	}

	const {
		href,
		pathname,
		origin,
		hash,
		target,
		search,
	} = closestAnchor;

	if (!href) {
		return;
	}

	event.preventDefault();

	const isTargetThirdParty = window.location.origin !== origin;

	if (isTargetThirdParty) {
		redirectToThirdPartyLink(closestAnchor);

		return;
	}

	const { slug } = getPathParams({
		path: pathname,
		languageKeys: languageKeys.value,
		defaultLocale: defaultLocale.value,
	});

	const targetPageId = slug ? Object.keys(pages.value).find((pageId) => pages.value[pageId].slug === slug) : homePageId.value;
	const isTargetPageCurrentPage = window.location.pathname === pathname;
	const queryParams = new URLSearchParams(search);
	const targetEcwidPage = queryParams.get('store-page');
	const isTargetPageWithEcwidBlock = Object.keys(ecwidPages.value).includes(targetPageId);
	const isTargetPageEcwid = !!targetEcwidPage || isTargetPageWithEcwidBlock;
	const shouldOpenInNewTab = target === '_blank';
	const fullPath = href.replace(origin, '');
	const currentFullPath = `${window.location.pathname}${window.location.search}${window.location.hash}`;

	if (isTargetPageCurrentPage && !shouldOpenInNewTab) {
		scrollToHash(hash);
	}

	if (shouldOpenInNewTab && !getIsInIframe()) {
		window.open(href, target);
	} else if (currentFullPath !== fullPath) {
		if (isTargetPageEcwid) {
			window.location.assign(href);
		} else if (props.isInPreviewMode) {
			router.push(fullPath);
		} else {
			window.location.assign(fullPath);
		}

		if (hash) {
			scrollToHash(hash);
		}

		isNavOpen.value = false;
	}

	if (!isTargetPageEcwid) {
		return;
	}

	if (!targetEcwidPage) {
		// @ts-ignore
		if (!window.Ecwid) {
			return;
		}

		openEcwidHomepage();

		return;
	}

	if (targetEcwidPage === 'cart') {
		openCart();
	}
};

// Since `pageData` is set to state during mount, it has to be updated via watcher every time the prop changes.
watch(() => props.pageData, (pageData) => {
	setPageData(pageData);
}, {
	immediate: true,
});

// The first watched has an `immediate` watcher - so it performs server-side compatible actions
// This watched has no immediate - so it will run only on the client.
watch(() => props.pageData, async () => {
	if (props.isInPreviewMode) {
		shouldMountAnimationsInPreview.value = true;
	}
});

onMounted(() => {
	scrollToHash(window.location.hash);
});
</script>
<template>
	<main
		v-if="shouldRenderPage"
		:style="pageCSSVars"
		class="page"
		@click="handleGlobalClick"
	>
		<StickyTrigger v-if="blocks.header.settings.isSticky" />
		<BlockHeader
			v-if="isHeaderVisible"
			v-bind="headerProps"
			@toggle-visibility="isNavOpen = !isNavOpen"
			@cart-click="handleCartClick"
		/>
		<!-- Simple blocks -->
		<div class="blocks">
			<BlockUser
				v-for="(blockId, index) of currentPageBlocks"
				:id="blocks[blockId].htmlId || blockId"
				:key="blockId"
				:data="blocks[blockId]"
				:blocks="blocks"
				:lcp="lcp"
				:page-id="currentPageId"
				:ecommerce-translations="ecommerceTranslations"
				:current-page-type="currentPageType"
				:components="elements"
				:style="index === 0 ? computedHeaderHeightStyles : undefined"
				:current-locale="currentLocale"
				:is-cart-visible="headerProps.isCartVisible"
				:is-in-preview-mode="props.isInPreviewMode"
			/>
		</div>

		<CookieBanner
			v-if="meta.isCookieBarEnabled"
			:disclaimer="cookieBannerDisclaimer || ''"
			:accept-text="cookieBannerAcceptText"
			:decline-text="cookieBannerDeclineText"
			:site-meta="meta"
		/>
		<EcommerceShoppingCartProviderUser
			v-if="isLocaleWithEcommerceItems"
			:ecommerce-translations="ecommerceTranslations"
			:language="language"
			:is-header-sticky="headerProps.isSticky"
			:is-nav-hidden="!isHeaderVisible"
			:current-page-ecommerce-blocks="currentPageEcommerceBlocks"
			:current-page-ecommerce-components="currentPageEcommerceComponents"
			:is-in-preview-mode="props.isInPreviewMode"
		/>
		<EcommerceModalRoot
			:ecommerce-translations="ecommerceTranslations"
			:language="language"
			:current-locale="currentLocale"
			:is-cart-visible="headerProps.isCartVisible"
			:is-in-preview-mode="props.isInPreviewMode"
		/>
	</main>
	<PasswordPage
		v-else
		:page-id="currentPageId"
		:locale="currentLocale"
		:default-locale="defaultLocale"
		:in-preview-mode="isInPreviewMode"
		:current-page-data="currentPage"
		:homepage-name="homepageName"
		:is-current-page-homepage="isCurrentPageHomepage"
	/>
	<Lightbox v-if="isLightboxOpen" />
</template>

<style lang="scss">
.page {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}
</style>
